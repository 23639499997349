import Slider from 'react-slick';
import AnimalSheltersCard from './AnimalSheltersCard';
import ServiceCard from '../Services/ServiceCard';
import PropTypes from 'prop-types';

const AnimalShelterSlider = (props) => {

  const SliderWithCards = () => {
    if (props.animalShelters && props.animalShelters.length > 0) {
      return (
        <Slider {...settings}>
          {
            [...props.animalShelters].map((animalShelter) => (
              <AnimalSheltersCard animalShelter={animalShelter} key={animalShelter.name} />
            ))
          }
        </Slider>
      );
    }

    return (
      <Slider>
        <ServiceCard service={{name: 'Nincsenek megjeleníthető menhelyek :('}} className='info' />
      </Slider>
    );
  };

  const settings = {
    dots: false,
    arrows: true,
    initialSlide: 0,
    speed: 500,
    rows: 1,
    slidesToShow: Math.min(4, props.animalShelters.length),
    slidesToScroll: Math.min(4, props.animalShelters.length),
    mobileFirst: true,
    appendDots: dots => (
      <div>
        <ul className='dots'> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className='dot'>{i + 1}</div>
    ),
    responsive: [
      {
        breakpoint: 1250,
        settings: {
          slidesToShow: Math.min(3, props.animalShelters.length),
          slidesToScroll: Math.min(3, props.animalShelters.length),
          initialSlide: 0,
          rows: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          rows: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className='animal-shelter-slider'>
      <SliderWithCards />
    </div>
  );
};

AnimalShelterSlider.propTypes = {
  animalShelters: PropTypes.array.isRequired,
};

export default AnimalShelterSlider;