import Link from '../Navigation/Link';
import PropTypes from 'prop-types';

const FooterItems = (props) => {
  return (
    <li className="footer-items">
      <Link link={props.items.path} text={props.items.title} />
    </li>
  );
};

FooterItems.propTypes = {
  items: PropTypes.object,
};

export default FooterItems;
