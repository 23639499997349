import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const AnimalSheltersCard = (props) => {

  const getImage = () => {
    if (props.animalShelter.images && props.animalShelter.images.length > 0)
      return [...props.animalShelter.images][0]['url'];

    return '';
  };

  return (
    <div className={ `service__card--animal-shelter ${props.className ?? ''}` }>
      <img className="service__card--animal-shelter__image" src={getImage()} alt="animal shelter"></img>
      <h3 className="service__card--animal-shelter__name">{props.animalShelter.name}</h3>
      <div className="service__card--animal-shelter__description">{props.animalShelter.description}</div>
      <Link className="service__card--animal-shelter__link" to={`/animal-shelters/${props.animalShelter.id}`}>tovább</Link>
    </div>
  );
};

AnimalSheltersCard.propTypes = {
  className: PropTypes.string,
  animalShelter: PropTypes.shape({
    images: PropTypes.array.isRequired,
    name: PropTypes.string,
    description: PropTypes.string,
    id: PropTypes.number.isRequired,
  }),
};

export default AnimalSheltersCard;
