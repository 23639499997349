import { useEffect, useState } from 'react';
import Button from '../Navigation/Button' ;
import { getAnimalShelters } from '../../api/AnimalShelter';
import { useTranslation } from 'react-i18next';
import AnimalShelterSlider from './AnimalShelterSlider';

const AnimalShelters = () => {
  const [animalShelters, setAnimalShelters] = useState();
  const { t } = useTranslation();
  
  useEffect(() => {
    getAnimalShelters().then((response) => {
      setAnimalShelters(response['data']['data']);
    });
  }, []);

  const Slider = () => {
    if (animalShelters) return (
      <AnimalShelterSlider animalShelters={animalShelters} />
    );
  };

  return (
    <>  
      <div className="service__header">
        <h2 className="service__header__title">{t('shelters')}</h2>
        <Button className="service__header__btn" text={t('more')} link='animal-shelters'/>
      </div>
      
      <Slider />
    </>
  );
};

export default AnimalShelters;