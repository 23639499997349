import DetailsFrame from './DetailsFrame';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import PetImage from './PetImage';
import PetGallery from './PetGallery';
import { getAnimalShelter } from '../../api/AnimalShelter';

// eslint-disable-next-line no-undef
const apiURL = process.env.REACT_APP_API_URL;

const PetDetails = () => {
  const [pet, setPet] = useState({});
  const { id } = useParams();
  const [petImages, setPetImages] = useState();
  const [animalShelter, setAnimalShelter] = useState();

  useEffect(() => {
    axios.get(`${apiURL}/pets/` + id).then((response) => {
      setPet(response.data.data);
      setPetImages(getPetImages(response.data.data));
      
      if (response.data.data.organization_id) {
        getAnimalShelter(response.data.data.organization_id).then((response) => {
          setAnimalShelter(response.data.data);
        });
      }
    });
  }, [id]);

  if (!Object.keys(pet).length) {
    return null;
  }

  function getPetImages(pet) {
    if(!pet.images) {
      return null;
    }

    const images = pet.images.map(image => 
      <PetImage 
        image={image.url}
        key={image.url}
      />);

    return images;
  }

  const Gallery = () => {
    if (petImages && petImages.length > 0) return (
      <PetGallery pet={pet} petImages={petImages} />
    );
  };

  const Informations = () => {
    if (petImages && animalShelter) return (
      <DetailsFrame pet={pet} animalShelter={animalShelter} />
    );
  };

  return (
    <div className='flex flex--column flex--center'>
      <div className="section section__pet-details">
        <div className='pet-details__header'>
          <h2>Adatlap</h2>
          <p>{pet.name}</p>
        </div>
        <div className="pet-details__pet">
          
          <Gallery />

          <Informations />
        </div>
      </div>
    </div>
  );
};


export default PetDetails;
