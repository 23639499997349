import Slider from 'react-slick';
import NavArrow from './PetNav/NavArrow';
import PetNameTag from './PetNav/PetNameTag';
import Proptypes from 'prop-types';
import { useState } from 'react';
import useWindowDimensions from '../../utils/windowDimensions';

const PetGallery = (props) => {

  const [singleImageSlider, setSingleImageSlider] = useState();
  const [allImageSlider, setAllImageSlider] = useState();

  const singleImageSliderSettings = {
    className: 'pet-details__pet-image',
    asNavFor: allImageSlider,
    ref: slider => setSingleImageSlider(slider),
    fade: true,
    prevArrow: <NavArrow/>,
    nextArrow: <NavArrow/>,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const { width } = useWindowDimensions();

  const slidesToShow = () => {
    if (width > 1024) {
      return Math.min(4, props.petImages.length);
    }

    return Math.min(props.petImages.length, Math.floor((width - 50) / 85));
  };

  const allImageSliderSettings = {
    className: 'pet-details__pet-image-slide',
    asNavFor: singleImageSlider,
    ref: slider => setAllImageSlider(slider),
    dots: true,
    swipe: false,
    focusOnSelect: true,
    arrows: false,
    slidesToScroll: 1,
    slidesToShow: slidesToShow(),
    initialSlide: 0,
    appendDots: dots => (
      <div>
        <ul> {dots} </ul>
      </div>
    ),
    customPaging: () => (
      <span className='small-dot'></span>
    ),
  };

  return (
    <div className="pet-gallery">
      <Slider {...singleImageSliderSettings}>
        {props.petImages}
      </Slider>

      <div className='pet-gallery__info'>
        <PetNameTag className="pet-name-tag" pet={props.pet} needBreedType={true} />
      </div>
        
      <Slider {...allImageSliderSettings}>
        {props.petImages}
      </Slider>
    </div>
  );
};

PetGallery.propTypes = {
  pet: Proptypes.object.isRequired,
  petImages: Proptypes.array.isRequired,
};

export default PetGallery;