import PropTypes from 'prop-types';
import male from '../../images/male.svg';
import female from '../../images/female.svg';
import { Link } from 'react-router-dom';

const PetCard = (props) => {

  const getImage = () => {
    if (props.pet.images && props.pet.images.length > 0) return [...props.pet.images][0]['url'];

    return '';
  };

  return (
    <Link className={`pet-card ${props.responsive ? 'pet-card--resp' : ''}`} to={`/pets/${props.pet.id}`}>
      <img className={`pet-card__img ${props.responsive ? 'pet-card__img--resp' : ''}`} src={getImage()} alt='cute image'/>
      <div className={`pet-card__inf ${props.responsive ? 'pet-card__inf--resp' : ''}`}>
        <div className={`pet-card__header ${props.responsive ? 'pet-card__header--resp' : ''}`}>
          <h3 className={`pet-card__name ${props.responsive ? 'pet-card__name--resp' : ''}`}>{props.pet.name}</h3>
          <img className={`pet-card__gender ${props.responsive ? 'pet-card__gender--resp' : ''}`} src={props.pet.gender === 'male' ? male : female} alt='gender' />
        </div>
        <p className={`pet-card__text ${props.responsive ? 'pet-card__text--resp' : ''}`}>{props.responsive ? '' : `${props.pet.breed.name}, `}{`${props.pet.birth_date}`}</p>
      </div>
    </Link>
  );
};

PetCard.propTypes = {
  pet: PropTypes.shape({
    id: PropTypes.number.isRequired,
    images: PropTypes.array.isRequired,
    name: PropTypes.string.isRequired,
    gender: PropTypes.string.isRequired,
    birth_date: PropTypes.string.isRequired,
    breed: PropTypes.shape({
      name: PropTypes.string,
    }),
  }),
  responsive: PropTypes.bool.isRequired,
};

export default PetCard;