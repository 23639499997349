import Link from '../Navigation/Link';
import PropTypes from 'prop-types';

const MenuItems = (props) => {
  return (
    <div onClick={props.toggleMenu}>
      <li className="menu-items">
        <Link
          link={props.items.path}
          text={props.items.title}
          image={props.items.image}
        />
      </li>
    </div>
  );
};

MenuItems.propTypes = {
  toggleMenu: PropTypes.func.isRequired,
  items: PropTypes.object,
  path: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  image: PropTypes.object,
};

export default MenuItems;
