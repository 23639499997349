import Adoption from './components/Pet/Adoption';
import PetDetails from './components/Pet/PetDetails';
import Add from './components/Pet/Add';
import Login from './components/User/Login';
import Lost from './components/Pet/Lost';
import Donation from './components/User/Donation';
import Collections from './components/User/Collections';
import AboutUs from './components/About/AboutUs';
import Terms from './components/About/Terms';
import Faq from './components/About/Faq';
import { Routes, Route, useLocation } from 'react-router-dom';
import Landing from './components/Landing/Landing';
import 'slick-carousel/slick/slick.css';
import Header from './components/Header/Header';
import './styles/styles.css';
import ServiceDetailed from './components/Services/ServiceDetailed';
import AnimalSheltersDetailed from './components/AnimalShelter/AnimalSheltersDetailed';
import AnimalShelter from './components/AnimalShelter/AnimalShelter';

function App() {

  const pathName = useLocation().pathname;

  return (
    <>
      {pathName === '/' && <Header/>}
      <div className={`main ${pathName === '/' ? 'main--landing': ''}`}>
        <Routes>
          <Route path="/" element={<Landing />} />
          <Route path="add" element={<Add />} />
          <Route path="adoption" element={<Adoption />} />
          <Route path="lost" element={<Lost />} />
          <Route path="pets/:id" element={<PetDetails />} />
          <Route path="animal-clinics" element={<ServiceDetailed serviceType='vet'/>} />
          <Route path="animal-clinics/:id" element={<ServiceDetailed serviceType='vet' />} />
          <Route path="animal-shelters" element={<AnimalSheltersDetailed />} />
          <Route path="animal-shelters/:id" element={<AnimalShelter />} />
          <Route path="animal-hotels" element={<ServiceDetailed serviceType='pet_hotel' />} />
          <Route path="animal-hotels/:id" element={<ServiceDetailed serviceType='pet_hotel' />} />
          <Route path="login" element={<Login />} />
          <Route path="donation" element={<Donation />} />
          <Route path="collections" element={<Collections />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="terms" element={<Terms />} />
          <Route path="faq" element={<Faq />} />
        </Routes>
      </div>
    </>
  );
}

export default App;
