import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import ServiceCardDetails from './ServiceCardDetails';
import PropTypes from 'prop-types';
import { getService } from '../../api/Service';
import ServiceCard from './ServiceCard';
import { useTranslation } from 'react-i18next';

const ServiceDetailed = (props) => {
  const [services, setServices] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(Number(useParams()['id']));
  const { i18n } = useTranslation();
  
  const toggleDetailedServiceCard = (index) => {
  
    if (index === currentIndex) {
      setCurrentIndex(null);
    } else {
  
      setCurrentIndex(index);
    }
  };
  
  useEffect(() => {
    getService(props.serviceType).then((response) => {
      setServices(response['data']['data']);
    });
  }, [i18n.language]);
  
  return (
    <div className='services'>  
      {services.map((serv, index) => (
        <div className={`service__card service__card--detailed ${index === currentIndex ? 'service__card--detailed--open' : ''}`} onClick={() => toggleDetailedServiceCard(index)} key={index}>
          <ServiceCard service={serv} key={serv.name} />
          <ServiceCardDetails service={serv} className={index === currentIndex ? 'service__card__details--show' : 'service__card__details'} key={index}/>
        </div>
      ))}
    </div>
  );
};
  
ServiceDetailed.propTypes = {
  serviceType: PropTypes.string,
};

export default ServiceDetailed;