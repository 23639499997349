import PropTypes from 'prop-types';

const PetFilterSelector = (props) =>{
  const buttonClicked = (index) => {
    if (props.currentIndex === index) {
      props.setCurrentindex(-1);
    } else {
      props.setCurrentindex(index);
    }
  };
  
  return(
    <div className="species_holder">
      {
        props.species.map((specie, index) => {
          return (
            <div key={index} className="species" >
              <button className={`species__checkbox ${props.currentIndex === index ? 'species__checkbox--selected' : ''}`} onClick={() => buttonClicked(index)} />
              <p className='species__name'>{specie.name}</p>
            </div>
          );
        })
      }
    </div>
  );
};

PetFilterSelector.propTypes = {
  species: PropTypes.array,
  currentIndex: PropTypes.number.isRequired,
  setCurrentindex: PropTypes.func.isRequired,
};
export default PetFilterSelector;