import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getAnimalShelter } from '../../api/AnimalShelter';
import place_black from '../../images/place_black.svg';
import paw_icon from '../../images/paw_icon.svg';
import divider from '../../images/Divider.svg';
import Phone from '../../images/Phone.svg';
import Email from '../../images/Email.svg';
import PetCard from '../Pet/PetCard';
import Gallery from './Gallery';

const AnimalShelter = () => {
  const id = Number(useParams()['id']);
  const [animalShelter, setAnimalShelter] = useState([]);

  const RenderGallery = () => {
    if (animalShelter.images) { 
      const imgs = [...animalShelter.images];

      if (imgs.length > 0)
        return (
          <Gallery images={[...animalShelter.images]} />
        );
    }
  };

  const Description = () => {
    if (animalShelter.description) return (
      <div className='animal-shelter__info__desc'>
        <h3>Leírás a menhelyről:</h3>
        <p>{animalShelter.description}</p>
      </div>
    );
  };

  const numberOfPets = () => {
    if (animalShelter.pets) {
      return [...animalShelter.pets].length;
    }

    return 0;
  };

  const Pets = () => {
    if (animalShelter.pets && animalShelter.pets.length > 0) {
      return (
        <div className='animal-shelter__pets'>
          <h2 className='animal-shelter__pets__header'>Fogadj örökbe!</h2>
          <div className='animal-shelter__pets__data'>
            {
              [...animalShelter.pets].map((pet) => {
                if (pet.status === 'awayting adoption') {
                  return <PetCard pet={pet} key={pet.id} responsive={true} />;
                }
              })
            }
          </div>
        </div>
      );
    }
  };

  useEffect(() => {
    getAnimalShelter(id).then((response) => {
      setAnimalShelter(response['data']['data']);
    });
  }, []);

  return (
    <div className='flex flex--column flex--center'>
      <div className='animal-shelter'>

        <div className='animal-shelter__header'>

          <RenderGallery />

          <div className='animal-shelter__info'>
            <div className='animal-shelter__info__main'>
              <h2 className='animal-shelter__info__name'>{animalShelter.name}</h2>
              <img className='animal-shelter__info__divider' src={divider} />
              <div className='animal-shelter__info__item'>
                <img src={place_black} alt='place' />
                <p>Helyszín: {animalShelter.address}</p>
              </div>
              <div className='animal-shelter__info__item'>
                <img src={paw_icon} alt='paw' />
                <p>{numberOfPets()} állat vár gazdira</p>
              </div>
              <div className='animal-shelter__info__item'>
                <img src={Phone} alt="phone"></img>
                <p>{animalShelter.phone_number}</p>
              </div>
              <div className='animal-shelter__info__item'>
                <img src={Email} alt="email"></img>
                <p>{animalShelter.email}</p>
              </div>
            </div>
            
            <Description />
          </div>
        </div>

        <Pets />

      </div>
    </div>
  );
};

export default AnimalShelter;