import Button from '../Navigation/Button';
import leftArrow from '../../images/left_arrow.svg';

const backNavImg = <img src={leftArrow} alt="back button" />;

const BottomNav = () => {
  return (
    <div className="pet-details__bottom-nav">
      <Button className={'button--40px'} link={'/'} text={backNavImg} />
    </div>
  );
};

export default BottomNav;
