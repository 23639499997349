import LeftArrow from '../../../images/left_arrow.svg';
import PropTypes from 'prop-types';

const NavArrow = (props) => {
  return (
    <div className={props.className} onClick={props.onClick}>
      <img
        src={LeftArrow}
        alt="nav arrow"
      />
    </div>
  );
};

NavArrow.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

export default NavArrow;
