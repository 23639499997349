import { useState } from 'react';
import { useEffect } from 'react';
import { getAnimalShelters } from '../../api/AnimalShelter';
import AnimalSheltersCard from './AnimalSheltersCard';

const ServiceDetailed = () => {
  const [animalShelters, setAnimalShelters] = useState([]);
  
  useEffect(() => {
    getAnimalShelters().then((response) => {
      setAnimalShelters(response['data']['data']);
    });
  }, []);
  
  return (
    <div className='services'>  
      {animalShelters.map((animalShelt) => (
        <AnimalSheltersCard animalShelter={animalShelt} key={animalShelt.name} />
      ))}
    </div>
  );
};

export default ServiceDetailed;