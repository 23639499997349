import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

const Gallery = (props) => {

  const [images, setImages] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const selectImage = (index) => {
    setCurrentIndex(index);
  };

  const GalleryIndex = () => {
    if (images && images.length > 0) return (
      <img className='animal-shelter__gallery__ind' src={images[currentIndex]} alt='index' />
    );
  };

  const GalleryListItem = (index) => {
    if (images[index]) return (
      <img className='animal-shelter__gallery__li' src={images[index]} onClick={() => {selectImage(index);}} key={index} />
    );
  };

  const GalleryList = () => {
    if (images && images.length > 1) return (
      <div className='animal-shelter__gallery__l'>
        {images.map((img, index) => {
          if (index < 3) return GalleryListItem(index);
        })}
      </div>
    );
  };

  useEffect(() => {
    const imgs = [];
    
    props.images.map((img) => {
      imgs.push(img.url);
    });

    setImages(imgs);
  }, []);

  return (
    <div className='animal-shelter__gallery'>
      <GalleryIndex />
      <GalleryList />
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.array,
};

export default Gallery;