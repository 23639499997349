import PropTypes from 'prop-types';

const DetailBox = (props) => {

  const Attributes = () => {
    if (props.pet.attribute_value && props.pet.attribute_value.length > 0) return (
      <>
        {
          [...props.pet.attribute_value].map((attr) => (
            <div className='pet-details__item' key={attr.attribute.name}>
              <h3>{attr.attribute.name}</h3>
              <span className='font-body-small'>{attr.name}</span>
            </div>
          ))
        }
      </>
    );
  };

  return (
    <div className="pet-details__box">
      <h2 className="pet-details__box-title">Adatok rólam</h2>

      <div className='pet-details__item'>
        <h3>Név</h3>
        <span className='font-body-small'>{props.pet.name}</span>
      </div>

      <div className='pet-details__item'>
        <h3>Fajta</h3>
        <span className='font-body-small'>{props.pet.breed.name}</span>
      </div>

      <div className='pet-details__item'>
        <h3>Születési év</h3>
        <span className='font-body-small'>{props.pet.birth_date}</span>
      </div>

      <div className='pet-details__item'>
        <h3>Státusz</h3>
        <span className='font-body-small'>{props.pet.status}</span>
      </div>

      <Attributes />
    </div>
  );
};

DetailBox.propTypes = {
  pet: PropTypes.shape({
    name: PropTypes.string.isRequired,
    breed: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }),
    birth_date: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
    attribute_value: PropTypes.array.isRequired,
  }),
};

export default DetailBox;
