import PropTypes from 'prop-types';

const ServiceCard = (props) => {

  const getImage = () => {
    if (props.service.images && props.service.images.length > 0) {
      return props.service.images[0]['url'];
    }

    return '';
  };

  return (
    <div className={ `service__card ${props.className ? props.className : ''}` }>
      <img className="service__card__image" src={getImage()} alt="service"></img>
      <div className="service__card__text">
        <h3 className="service__card__text__name">{props.service.name}</h3>
        <p className="service__card__text__address">{props.service.address}</p>
        <p className="service__card__text__opening-hours">{props.service.opening_hours}</p>
      </div>
    </div>
  );
};

ServiceCard.propTypes = {
  className: PropTypes.string,
  service: PropTypes.shape({
    images: PropTypes.array,
    name: PropTypes.string,
    address: PropTypes.string,
    opening_hours: PropTypes.string,
  }),
};

export default ServiceCard;
