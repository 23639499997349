import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const Button = (props) => {
  return (
    <Link to={props.link}>
      <button className={props.className}>{props.text}</button>
    </Link>
  );
};

Button.propTypes = {
  link: PropTypes.string,
  className: PropTypes.string,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};


export default Button;
