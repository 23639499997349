import { useEffect, useState  } from 'react';
import PetFilterSelector from './PetFilterSelector';
import PetFilterSlider from './PetFilterSlider';
import PetFilterHeader from './PetFilterHeader';
import { getSpecies } from '../../api/Species';
import { getPets } from '../../api/Pets';

const PetFilter = () => {
  const [species, setSpecies] = useState([]);
  const [pets, setPets]= useState([]);
  const [currentIndex, setCurrentindex] = useState(-1);

  useEffect(() => {
    getSpecies().then((response) => {
      setSpecies(response['data']['data']);
    });

    if (currentIndex >= 0 && currentIndex < species.length) {
      getPets(species[currentIndex].id).then((response) => {
        setPets(response['data']['data']);
      });
    } else {
      getPets().then((response) => {
        setPets(response['data']['data']);
      });
    }
  }, [currentIndex]);
	
  return (
    <>
      <PetFilterHeader/> 
      <PetFilterSelector species={species} currentIndex={currentIndex} setCurrentindex={setCurrentindex} />
      <PetFilterSlider pets={pets}/> 
    </>
       
  );
};

export default PetFilter;
