import DetailBox from './DetailBox';
import MessageBox from './MessageBox';
import BottomNav from './BottomNav';
import PropTypes from 'prop-types';
import ServiceCard from '../Services/ServiceCard';
import { Link } from 'react-router-dom';
  
const DetailsFrame = (props) => {

  const AnimalShelter = () => {
    if (props.animalShelter) return (
      <Link to={`/animal-shelters/${props.animalShelter.id}`}>
        <ServiceCard service={props.animalShelter} />
      </Link>
    );
  };

  return (
    <div className="details-frame-border">
      <div className="details-frame">
        <DetailBox pet={props.pet} />
        <MessageBox petStory={props.pet.story} />
        <AnimalShelter />
        <BottomNav />
      </div>
    </div>
  );
};

DetailsFrame.propTypes = {
  pet: PropTypes.object.isRequired,
  animalShelter: PropTypes.object.isRequired,
};

export default DetailsFrame;
