import Link from '../Navigation/Link';
import Menu from '../Menu/Menu';
import profilePic from '../../images/profile.svg';
import PropTypes from 'prop-types';

const Navbar = (props) => {
  return (
    <nav className="navbar__items">
      <Menu
        className="menu-toggle__holder"
        toggleMenu={props.toggleMenu}
      />
      <Link className="profile" link="/login" image={profilePic} />
    </nav>
  );
};

Navbar.propTypes = {
  toggleMenu: PropTypes.func,
};

export default Navbar;
