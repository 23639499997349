import Phone from '../../images/Phone.svg';
import Line21 from '../../images/Line21.svg';
import Email from '../../images/Email.svg';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const ServiceCardDetails = (props) => {
  const { t } = useTranslation();

  return (
    <div className={props.className}>
      <p className="service__card__details__notes">{props.service.description}</p>
      <div className="service__card__details__item">
        <h3>{t('opening_hours')}</h3>
        <p>{props.service.opening_hours}</p>
      </div>
      <div className="service__card__details__item">
        <h3>{t('contacts')}</h3>
        <div>
          <img src={Phone} alt="phone"></img>
          <p>{props.service.phone_number}</p>
        </div>
        <div>
          <img src={Email} alt="email"></img>
          <p>{props.service.email}</p>
        </div>
      </div>
      <div className="service__card__details__line">
        <img src={Line21} alt="line"></img>
      </div>
    </div>
  );
};

ServiceCardDetails.propTypes = {
  className: PropTypes.string,
  service: PropTypes.object,
};

export default ServiceCardDetails;