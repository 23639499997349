import { useEffect, useState } from 'react';
import Button from '../Navigation/Button' ;
import PropTypes from 'prop-types';
import { getService } from '../../api/Service';
import ServiceSlider from './ServiceSlider';
import { useTranslation } from 'react-i18next';

const Service = (props) => {
  const [services, setServices] = useState([]);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    getService(props.serviceType).then((response) => {
      setServices(response['data']['data']);
    });
  }, [i18n.language]);

  return (
    <>  
      <div className="service__header">
        <h2 className="service__header__title">{props.name}</h2>
        <Button className="service__header__btn" text={t('more')} link={props.linkToMore ? props.linkToMore : ''}/>
      </div>
      <ServiceSlider services={services} linkBase={props.linkToMore} />
    </>
  );
};

Service.propTypes = {
  serviceType: PropTypes.string,
  name: PropTypes.string.isRequired,
  linkToMore: PropTypes.string,
};

export default Service;