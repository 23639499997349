import axios from 'axios';

axios.interceptors.request.use(function (config) {
  config.headers['Accept-Language'] = localStorage.getItem('language');
  return config;
}, function (error) {
    
  return Promise.reject(error);
});

export default axios;