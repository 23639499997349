import Navbar from '../Navbar/Navbar';
import MenuItems from '../Menu/MenuItems';
import { useState } from 'react';
import rightArrow from '../../images/right_arrow.svg';
import Menu from '../Menu/Menu';
import home from '../../images/home.svg';
import medical_services from '../../images/medical_services.svg';
import paid from '../../images/paid.svg';
import paw_icon from '../../images/paw_icon.svg';
import report from '../../images/report.svg';
import volunteer_activism from '../../images/volunteer_activism.svg';
import { useTranslation } from 'react-i18next';
//import SelectLanguages from '../SelectLanguages/SelectLanguages';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const { t } = useTranslation();
  const menuItems = [
    {
      title: t('adoption'),
      path: '/adoption',
      image: paw_icon,
    },
    {
      title: t('lost_animals'),
      path: '/lost',
      image: report,
    },
    {
      title: t('shelters'),
      path: '/animal-shelters',
      image: home,
    },
    {
      title: t('clinics'),
      path: '/animal-clinics',
      image: medical_services,
    },
    {
      title: t('donation'),
      path: '/donation',
      image: paid,
    },
    {
      title: t('collections'),
      path: '/collections',
      image: volunteer_activism,
    },
    {
      title: t('cosmetics'),
      path: '/animal-hotels',
      image: home,
    },
  ];

  function toggleMenu() {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = !isMenuOpen ? 'hidden' : 'visible';
  }

  return (
    <header className={`header ${isMenuOpen ? 'menu-open' : ''}`}>
      <div className="wrapper">
        <div className="header__content">
          <Navbar toggleMenu={toggleMenu} />
        </div>
      </div>
      <div className="menu">
        <div className="wrapper">
          <div className="menu__holder">
            <nav className="menu__nav" onClick={toggleMenu}>
              <Menu
                className="menu-toggle__holder"
                text={t('menu')}
                toggleMenu={toggleMenu}
              />
              <img src={rightArrow} alt="right_arrow" />
            </nav>
            <ul className="menu__list">
              {menuItems.map((menu, index) => {
                return (
                  <MenuItems
                    items={menu}
                    key={index}
                    toggleMenu={toggleMenu}
                  />
                );
              })}
              {/* Required for translation, displays the button and implements method
                <SelectLanguages /> */}
            </ul>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
