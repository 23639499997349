import Button from '../Navigation/Button';
import { useTranslation } from 'react-i18next';

function banner() {
  const { t } = useTranslation();

  return (
    <div>
      <div className="banner-image"></div>
      <div className="wrapper">
        <div className="title-holder">
          <h3 className="title">
            {t('title_banner')}
          </h3>

          <div className="start-adoption">
            <div className="title_adoption">
              <h3>{t('title_adoption_banner')}</h3>
            </div>

            <div className="start-adoption__button">
              <Button
                className="button--40px"
                link="/adoption"
                text={t('adoption')}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default banner;
