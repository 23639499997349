import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationHU from './locales/hu/translation.json';
import translationRO from './locales/ro/translation.json';

const resources = {
  hu: {
    translation: translationHU,
  },
  ro: {
    translation: translationRO,
  },
};

const lang = () => {
  if (!localStorage.getItem('language')){
    return 'hu';
  }

  return localStorage.getItem('language');
  
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lang(),
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;