import PropTypes from 'prop-types';

const PetImage = (props) => {
  return (
    <img
      className={`pet__image ${props.className}`}
      src={props.image}
      alt="pet"
    />
  );
};

PetImage.propTypes = {
  className: PropTypes.string,
  image: PropTypes.string.isRequired,
};

export default PetImage;
