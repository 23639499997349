import menuIcon from '../../images/menu.svg';
import PropTypes from 'prop-types';

const Menu = (props) => {
  return (
    <div className={props.className} onClick={props.toggleMenu}>
      <img src={menuIcon} alt="menu" />
      {props.text && <h3 className="menu__text">{props.text}</h3>}
    </div>
  );
};

Menu.propTypes = {
  className: PropTypes.string,
  toggleMenu: PropTypes.func.isRequired,
  text: PropTypes.string,
};

export default Menu;
