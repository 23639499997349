import FooterItems from '../FooterList/FooterItems';
import MinicLogo from '../../images/Logo_horizontal.svg';

import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t } = useTranslation();
  const footerItems = [
    {
      title: t('aboutus'),
      path: '/aboutus',
    },
    {
      title: t('faq'),
      path: '/faq',
    },
    {
      title: t('terms'),
      path: '/terms',
    },
  ];

  return (
    <>
      <div className="footer__holder">
        <ul className="footer__list">
          {footerItems.map((menu, index) => {
            return <FooterItems items={menu} key={index} />;
          })}
        </ul>
        <div className="line"> </div>
        <img
          className="image_adjustments"
          src={MinicLogo}
          alt="Minic"
        />
      </div>
    </>
  );
};
export default Footer;
