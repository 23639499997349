import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

const link = (props) => {
  const component = (
    <>
      {props.image && (
        <div className="link__image">
          <img src={props.image} alt="icon" />
        </div>
      )}

      {props.text && (
        <div className="link__text">
          <span className="link">{props.text}</span>
        </div>
      )}
    </>
  );

  if (props.className) {
    return (
      <div className={props.className}>
        <Link to={props.link}>{component}</Link>
      </div>
    );
  }

  return <Link to={props.link}>{component}</Link>;
};

Link.propTypes = {
  image: PropTypes.object,
  className: PropTypes.string,
  link: PropTypes.string,
  text: PropTypes.string,
};

export default link;
