import Button from '../Navigation/Button';

const PetFilterHeader=() => {

  return (
    <div className="pet-filter__header">
      <h2 className="pet-filter__header__title">Örökbefogadás</h2>
      <Button className="pet-filter__header__btn" text='Több' link='animal-shelters'/>	
    </div>
  );
};

export default PetFilterHeader;
