import Slider from 'react-slick';
import ServiceCard from './ServiceCard';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ServiceSlider = (props) => {

  const SliderWithCards = () => {
    if (props.services && props.services.length > 0) {
      return (
        <Slider {...settings}>
          {
            [...props.services].map((serv, index) => (
              <Link to={`/${props.linkBase}/${index}`} key={index}>
                <ServiceCard service={serv} key={serv.name} />
              </Link>
            ))
          }
        </Slider>
      );
    }

    return (
      <Slider>
        <ServiceCard service={{name: 'Nincsenek megjeleníthető szolgáltatók :('}} className='info' />
      </Slider>
    );
  };

  const settings = {
    dots: false,
    arrows: true,
    initialSlide: 0,
    speed: 500,
    rows: props.services.length > 1 ? 2 : 1,
    slidesToShow: props.services.length > 2 ? 2 : 1,
    slidesToScroll: props.services.length > 2 ? 2 : 1,
    mobileFirst: true,
    appendDots: dots => (
      <div>
        <ul className='dots'> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className='dot'>{i + 1}</div>
    ),
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          rows: Math.min(2, props.services.length),
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 1,
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  return (
    <div className='service-slider'>
      <SliderWithCards />
    </div>
  );
};

ServiceSlider.propTypes = {
  services: PropTypes.array.isRequired,
  linkBase: PropTypes.string.isRequired,
};

export default ServiceSlider;