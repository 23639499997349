import Banner from '../Banner/Banner';
import Footer from '../Footer/Footer';
import Service from '../Services/Service';
import AnimalShelters from '../AnimalShelter/AnimalShelters';
import PetFilter from '../Filter/PetFilter';
import { useTranslation } from 'react-i18next';

const Landing = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className='flex flex--center flex--column m--25'>
        <Banner />
        <div className='section section__pet-filter'>
          <PetFilter />
        </div>
        <div className="section section__organization">
          <AnimalShelters />
        </div>
        <div className="section section__animal-clinics">
          <Service serviceType='vet' name={t('veterinarian')} linkToMore='animal-clinics' />
        </div>
        <div className="section section__animal-hotels">
          <Service serviceType='pet_hotel' name={t('animal_hotels')} linkToMore='animal-hotels' />
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Landing;
