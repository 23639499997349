import axios from './ApiBase';

// eslint-disable-next-line no-undef
const apiURL = process.env.REACT_APP_API_URL;

export const getAnimalShelters = () => {
  return axios.get(`${apiURL}/organizations`);
};

export const getAnimalShelter = (id) => {
  return axios.get(`${apiURL}/organizations/${id}`);
};
