import male from '../../../images/male.svg';
import female from '../../../images/female.svg';
import PropTypes from 'prop-types';

const PetNameTag = (props) => {
	
  const currentGender = props.pet.gender === 'male' ? male : female;

  return (
    <div className={`pet-name-tag ${props.className}`}>
      <img
        className="pet-gender-icon"
        src={currentGender}
        alt="pet gender"
      />
      <h2>{props.pet.name}</h2>
      {props.needBreedType && <span className='font-body'>{props.pet.breed.name}, </span>}
      <span className='font-body'>{props.pet.birth_date}</span>
    </div>
  );

};

PetNameTag.propTypes = {
  className: PropTypes.string,
  pet: PropTypes.object,
  needBreedType: PropTypes.bool,
};

export default PetNameTag;
