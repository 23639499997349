import PropTypes from 'prop-types';

const MessageBox = (props) => {
  return (
    <div className="pet-details__message">
      <h3>Üzent a gondozóimtól:</h3>
      <span className="font-body-small">{props.petStory}</span>
    </div>
  );
};

MessageBox.propTypes = {
  petStory: PropTypes.string.isRequired,
};

export default MessageBox;
