import PropTypes from 'prop-types';
import Slider from 'react-slick';
import PetCard from '../Pet/PetCard';
import ServiceCard from '../Services/ServiceCard';

const PetFilterSlider = (props) => {

  const settings = {
    dots: false,
    arrows: true,
    initialSlide: 0,
    speed: 500,
    rows: 1,
    slidesToShow: Math.min(4, props.pets.length),
    slidesToScroll: Math.min(4, props.pets.length),
    mobileFirst: true,
    appendDots: dots => (
      <div>
        <ul className='dots'> {dots} </ul>
      </div>
    ),
    customPaging: i => (
      <div className='dot'>{i + 1}</div>
    ),
    responsive: [
      {
        breakpoint: 1450,
        settings: {
          slidesToShow: Math.min(3, props.pets.length),
          slidesToScroll: Math.min(3, props.pets.length),
          initialSlide: 0,
          rows: 1,
        },
      },
      {
        breakpoint: 1130,
        settings: {
          slidesToShow: Math.min(2, props.pets.length),
          slidesToScroll: Math.min(2, props.pets.length),
          initialSlide: 0,
          rows: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
          rows: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  const SliderWithCards = () => {
    if (props.pets && props.pets.length > 0) {
      return (
        <Slider {...settings}>
          {
            [...props.pets].map((pet) => (
              <PetCard pet={pet} key={pet.name} responsive={false} />
            ))
          }
        </Slider>
      );
    }

    return (
      <Slider>
        <ServiceCard service={{name: 'Nincsenek megjeleníthető állatok :('}} className='info' />
      </Slider>
    );
  };
  
  return (
    <div className='pet-filter-slider'>
      <SliderWithCards />
    </div>
  );
};

PetFilterSlider.propTypes = {
  pets: PropTypes.array,
};

export default PetFilterSlider;